
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Main',
  setup() {
    return {
    }
  }
})
